.modal-back {position: fixed; left:0; background-color:rgba(0, 0, 0, 0.1); top:-100vh; opacity:0; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; transition: all 0.5s; z-index: 1;}
.modal-back.active {top:0; opacity:1;}
.modal-back .modal-wrapper { width:calc(90vw - 40px); padding:30px 20px; position:relative; background-color:rgba(0, 0, 0, 1); border-radius:12px; overflow: hidden;}

.modal-wrapper .modal-title {font-size:18px; font-family: samsungBold; margin-bottom:10px; color: white; text-align: center; max-width: 90%; margin: auto;}

.modal-main-tvList {z-index: 2;}
.modal-main-tvList .modal-wrapper {width: calc(100vw - 60px); padding: 30px 10px;}
.modal-main-tvList .modal-wrapper .modal-title {margin-bottom: 20px;}
.modal-main-tvList .modal-wrapper .row {margin: 30px 0px;}
.modal-main-tvList .modal-wrapper .button {width: calc(20% - 15px); margin: auto; border: 1px solid grey; font-size: 16px; font-family: samsungBold; font-weight: 500; letter-spacing: -0.2px;}
.modal-main-tvList .modal-wrapper .button#btnTVBrowse {min-width: 160px;}
/* background-image: linear-gradient(#0077C8 , #1428A0);  align-items: center; justify-content: center; flex-direction: column; border-radius: 10px; */

.modal-main-vipList .modal-wrapper {width: 360px; height: calc(100% - 100px);}
.modal-main-vipList .modal-content {width: 100%; max-height: 100%;}
.modal-main-vipList .modal-content img {width: 100%;}

.modal-main-tvList .modal-wrapper .button .main-label {font-size: 18px; font-family: samsungBold; margin-bottom: 10px;}
.modal-main-tvList .modal-wrapper .button .sub-label {font-size: 10px; text-align: center;}
.modal-main-exit .modal-wrapper {height: 50vh; background-color: rgba(0, 0, 0, 0.9);}
.modal-main-exit .button {padding: 0px 30px; margin: 0px 20px;}
.modal-main-photo .modal-wrapper {height: 50vh; background-color: rgba(0, 0, 0, 0.9);}
.modal-main-photo .modal-title {font-size: 28px;}
.modal-main-photo .modal-content {height: calc(100% - 60px);}
.modal-main-photo .button {padding: 0px 30px; margin: 0px 20px;}

.modal-main-surround {z-index: 4;}
.modal-main-surround .modal-wrapper .modal-content {flex-direction: column;}
.modal-main-surround .modal-wrapper .modal-content .note-label {margin: 10px 0px; font-size: 20px;}
.modal-main-surround .modal-wrapper .modal-content .button {padding: 0 40px; height: 35px; margin-top: 30px; font-family: 'samsungOne700';}

.modal-main-chromeNote {z-index: 4;}
.modal-main-chromeNote .modal-content {flex-direction: column;}
.modal-main-chromeNote .modal-content img {width: 40px; margin-bottom: 20px;}
.modal-main-chromeNote .modal-content .button {padding: 0px 60px; margin-top: 20px;}
.modal-main-chromeNote .modal-content .note-label {margin: 5px 0px; font-size: 20px; max-width: 80%; text-align: center; font-family: 'samsungOne400';}
.modal-main-chromeNote .modal-content .note-label label {font-size: 20px; font-family: 'samsungOne400'; color: #ebd076;}

.modal-terms .modal-wrapper .modal-content {max-height: calc(100vh - 200px); overflow-y: auto; margin-top: 20px; scroll-behavior: smooth;}
.modal-terms .close {top: 10px; right: 10px;}

#modalSub {z-index: 3; background-color: rgba(0, 0, 0, 0.2);}
#modalSub * {color: white; font-family: 'samsungSharp';}
#modalSub .modal-wrapper {height: calc(100% - 50px); width: calc(100vw - 55px); padding: 0px; margin-right: 40px; display: flex;
border: 1px solid white; border-radius: 0; overflow:hidden;
	clip-path: polygon(
		0 20px,
		20px 0,
		100% 0,
		100% calc(100% - 20px),
		calc(100% - 20px) 100%,
		0 100%,
		0 20px) }
#modalSub .modal-wrapper .oblique {position: absolute; height: 2px; width: 29px; transform: rotate(-45deg); background-color: white;}
#modalSub .modal-wrapper .oblique-top-left {top: 10px; left: -6px;}
#modalSub .modal-wrapper .oblique-bottom-right {bottom: 10px; right: -6px;}
#modalSub .modal-side { width: calc(50% - 2px); border: 1px solid white;}
#modalSub .tv-side .main-image { width: 100%; height: calc(100% - 120px);}
#modalSub .tv-side .main-image img {height: calc(100% - 16px); width: auto;}

#modalSub .tv-side .member-part { height: 120px; background-image: linear-gradient(to right, #0077C8 10%, #1428A0 90%);}
#modalSub .tv-side .member-part .member-title {height: 50px; border-top: 1px solid white; border-bottom: 1px solid #558ACD; font-size: 14px;}
#modalSub .tv-side .member-part .member-title img {height: 25px; margin-right: 10px;}
#modalSub .tv-side .member-part .member-des {height: 68px; position: relative;}
#modalSub .tv-side .member-part .member-des .label { flex:1; font-size: 12px; text-align: center; padding: 5px; font-family: 'samsungOne700';}
#modalSub .tv-side .member-part .member-des .label.hide { display: none;}
#modalSub .tv-side .member-part .member-des .middle-line { position: absolute; top: 0; left: 50%; height: 100%; width: 1px; background-color: #0077C8;}
#modalSub .tv-side .member-part .member-des .middle-line.hide { display: none;}

/* border: 5px solid #555555; */
#modalSub .tv-side #btnMember {width: 150px; height: 48px; font-size: 13px; margin: 0px auto; text-align: center; border-radius: 25px; padding: 0px 20px;}

#modalSub .text-side {background-color: rgba(255, 255, 255, 1);}
#modalSub .text-side * {color: black; font-size: 12px;}
#modalSub .text-side .side-title-wrapper {overflow: hidden; margin-top: 10px; padding: 0px 5px;}
#modalSub .text-side .title-row { display: flex; align-items: center; width: 100%; margin: 5px 0px;}
#modalSub .text-side .title-row .title-right { flex: 1; display: flex; justify-content: flex-end; align-items: center;}
#modalSub .close {padding: 5px; border-radius: 50%; border: 2px solid white;}
/* #modalSub .text-side .side-title:first-child {clip-path: polygon(
	0 5px,
	5px 0,
	100% 0,
	100% 100%,
	5px 100%,
	0 calc(100% - 5px),
	0 5px
)}
#modalSub .text-side .side-title.last-title {clip-path: polygon(
	0 0,
	calc(100% - 5px) 0,
	100% 5px,
	100% calc(100% - 5px),
	calc(100% - 5px) 100%,
	0 100%,
	0 0
)} */
/* #modalSub .text-side #labelTVMain { background-color: #1721A5; color: white; padding: 5px 8px; clip-path: polygon(
	0 5px,
	5px 0,
	calc(100% - 5px)  0,
	100%  5px,
	100% calc(100% - 5px),
	calc(100% - 5px) 100%,
	5px 100%,
	0 calc(100% - 5px),
	0 5px
)} */
#modalSub .text-side #labelTVMain {height: 30px; padding: 0px 10px; color: white; text-align: center;}
#modalSub.modal-sub-8K .text-side #labelTVMain {padding: 0px 20px;}
#modalSub .text-side .title-right .side-title-pre {color: black; margin-right: 5px; font-weight: 700;}
#modalSub .text-side .sub-tv-name-wrapper {background-color: #D9D9D9; display: flex; border-radius: 15px;}
#modalSub .text-side .sub-tv-name {padding: 3px 8px; font-weight: 700; background-color: #D9D9D9; border-radius: 15px; border: 2px solid #D9D9D9;}
#modalSub .text-side .sub-tv-name.hide {display: none;}
#modalSub .text-side .sub-tv-name.active {background-color: white; color: #1721A5; border-color: #1721A5;}
#modalSub .text-side .sub-tv-label.active { animation: pulse 2s infinite;} /* -webkit-animation: pulse 1.5s infinite; */
@keyframes		   pulse { 0% { opacity: 0.8; } 50% { transform: scale(1.2);  opacity: 1; } 100% { opacity: 0.8; } }
@-webkit-keyframes pulse { 0% { opacity: 0.8; } 50% { transform: scale(1.2);  opacity: 1; } 100% { opacity: 0.8; } }
#modalSub .text-side .title-row.inch-row .sub-tv-name{ margin:auto; font-size: 11.5px; letter-spacing: -0.1px;}
#modalSub .text-side .side-content {max-height: calc(100% - 121px); overflow-y: auto; padding-right: 10px; padding-left: 5px; overflow-x: hidden; margin-bottom: 3px;}
#modalSub .text-side .option-item {width: calc(100% - 10px); display: flex; padding: 7px 5px; position: relative;}
#modalSub .text-side .option-item.disclaimer {display: block;}
#modalSub .text-side .option-item.hide {display: none;}
#modalSub .text-side .option-item .int-line {position: absolute; top: 0px; left: 150px; height: 100%; width: 1px; background-color: #E6E6E6;}
#modalSub .text-side .option-item.blue .int-line { background-color: #558ACD;}

#modalSub .text-side .option-item:nth-child(odd) { background-color: #F4F4F4; }

#modalSub .text-side .option-item .label{ color: #1721A5; width: 145px; font-weight: 700;}
#modalSub .text-side .option-item .value{ color: black; flex: 1; font-family: 'samsungOne700'; text-align: center; word-break: break-word;}
#modalSub .text-side .option-item .value .value-top .italic { transform: skew(-15deg); float: left; margin-right: 5px;}
#modalSub .text-side .option-item .value .value-bottom { font-size: 10px;}
#modalSub .text-side .option-item.disclaimer .value {text-align: left;}
#modalSub .text-side .option-item.disclaimer .value div {margin-top: 7px;}
#modalSub .text-side .option-item .sub-tree {display: flex; margin-top: 4px;}
#modalSub .text-side .option-item .sub-tree .tree-sign {width: 25px; display: flex; justify-content: center;}
#modalSub .text-side .option-item .sub-tree .tree-sign .sign-line {width: 30%; height: 1.5px; background-color: black; margin-top: 7px;}
#modalSub .text-side .option-item .sub-tree .tree-sign .sign-dot {width: 6px; height: 6px; border-radius: 50%; background-color: black; margin-top: 5px;}
#modalSub .text-side .option-item .sub-tree .tree-cont {flex: 1;}
#modalSub .url {color: #0077c8; text-decoration: underline;}

#modalSub .text-side .option-item.one-item img {width: 25px; height: 25px; border-radius: 50%; border: 1px solid #1721A5;}
#modalSub .text-side .option-item.one-item .label {width: inherit; margin-left: 10px; font-weight: 700; width: initial;}
#modalSub #btnShop { color: white; width: 250px; height: 33px; margin: auto; font-size: 14px;}

#modalSeeMore .button {padding: 0px 30px; margin: 50px 20px;}
#modalSelect .modal-title {font-size: 30px; width: 450px; margin: auto;}
#modalSelect .button {padding: 0px 30px; margin: 30px 20px;}

@media (orientation: portrait) {
	.modal-main-tvList .modal-content .row {flex-direction: column;	}
	.modal-main-tvList .modal-content .row .button {width: 70%; height: 35px; margin: 10px 0px;}
	.modal-main-photo .modal-content {flex-direction: column;}
	.modal-main-photo .button {margin: 10px 0px;}
}
