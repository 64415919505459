

.back-video {display: none; position: absolute; z-index: 1;}
.back-video.show {display: block;}

#btnVideoSkip {position:absolute; bottom:-100px; left:calc(50% - 70px); padding:0px 30px; transition: all 0.3s; z-index: 1;}
#btnVideoSkip img {margin-left:20px; height:20px; transition: all 0.5s;}
#btnVideoSkip.back img {transform: rotate(180deg);}
#btnVideoSkip.active {bottom:20px; }

