
@font-face { font-family: "samsungBold"; src: url('../font/samsung-bold.otf'); }
@font-face { font-family: "samsungMedium"; src: url('../font/samsung-medium.otf'); }
@font-face { font-family: "samsung"; src: url('../font/samsung.otf'); }
@font-face { font-family: "samsungOne400"; src: url('../font/SamsungOne-400.ttf'); }
@font-face { font-family: "samsungOne700"; src: url('../font/SamsungOne-700.ttf'); }
@font-face { font-family: "samsungSharp"; src: url('../font/SamsungSharpBold.ttf'); }
* { font-family:"samsung"; color:white }

html,
body,
#root {
	width: 100%;
	height: 100%;
	touch-action: none;
}
  
body { margin: 0; padding: 0; } /* overflow: hidden; */
.page-wrapper { background-repeat: no-repeat; background-position: center; background-size: contain; position: fixed; background-image: linear-gradient(#1520A1 0%, #000000 40%, #000000 60%, #1520A1 100%);} /* overflow: hidden; */
.back-board { position: fixed; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; z-index: -1;} /* overflow: hidden; */
.back-board.active { opacity: 1; z-index: 1; }

.test-value {position: fixed; top: 10px; left: 10px; z-index: 5; opacity: 0;}
.test-value.active {opacity: 1;}

.flex {display: flex; align-items: center; justify-content: center;}
.close {position:absolute; top: 7px; right: 7px; width: 15px;}
.button {height:40px; border-radius:20px; display: flex; align-items: center; justify-content: center; background-image: linear-gradient(to right, #0077C8 10%, #1428A0 90%); font-family: samsungBold; font-size:16px; border: 1px solid grey; letter-spacing: -0.1px; color: white;}
.button#btnTVNext img {margin-left: 10px; height: 12px;}

.black-wrapper {background-color:rgba(0, 0, 0, 0.85); width:calc(100vw - 90px); height:calc(100% - 50px); border-radius:15px; padding:0px 10px;}

.loading .black-wrapper {flex-direction: column; width: 1100px; height: 800px;}
.loading .desktop-word {margin-top: 50px; flex-direction: column;}
.loading .desktop-word div {font-size: 30px; width: 700px; text-align: center; margin: 8px 0px; font-family: 'samsungBold';}
.loading .desktop-word div .link {font-weight: 700; margin-left: 5px; font-family: 'samsungBold'; color: #72bdf1;} /*  */

.loading img {width: 80vw; max-width: 550px;}
.loading .loading-circle { position: absolute; top: calc(50% - 50px); left: calc(50% - 50px); border: 16px solid #f3f3f3; border-top: 16px solid #3498db; border-radius: 50%; width: 90px; height: 90px; animation: spin 2s linear infinite; }
.loading .loading-circle.grey { border: 6px solid #444; border-top: 6px solid #DDDDDD; }
@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
.loading .desktop-word img {width: 300px; margin-top: 20px;}

/* .part0-start .logo {width:240px;} */
.part1-select .back-logo {position: absolute; width: 100%; height: 100%; top: 0; left: 0;}
.part1-select .back-logo img {width: 240px; height: 70px;}
.part1-select .black-wrapper {transform: scale(0.1); opacity: 0; transition: all 0.5s; height: calc(100% - 100px);}
.part1-select .black-wrapper.zoom {transform: scale(1); opacity: 1;}
.part1-select .select-part {width:50%; margin: 15px 0;}
.part1-select .select-part:first-child {margin: 0;}
.part1-select .select-part .logo {width:200px;}
.part1-select .select-part .select-item {flex-direction:column; background-image: linear-gradient(#0077C8 , #1428A0); width: 160px; height: 230px; border-radius:8px; border: 1px solid grey;}
.part1-select .select-part .select-item img {width: 106px; margin-bottom: 10px;}
.part1-select .select-part .select-item .label {font-size: 16px; font-family: samsungBold; letter-spacing: -0.1px;}
.part1-select .terms-button {margin-top: 0px; width: 280px; font-size: 12px; text-align: center; text-transform: uppercase;}
.part1-select .terms-label { flex-direction: column; margin-top: 20px;}
.part1-select .terms-label div { font-size: 12px; text-align: center;}
.part1-select .terms-label div:nth-child(2) { margin-top: 6px;}
.part1-select .loading { position: absolute; top: 0; left: 0; width: 0; height: 0; display: none; background-color: rgba(0, 0, 0, 0.3);}
.part1-select .loading.active { width: 100%; height: 100%; display: block;}

#part3Video #btnTap { padding: 0px 20px; margin-top: calc(100vh - 155px); font-size: 24px; font-weight: 700; font-family: samsungBold; color: white; text-shadow: 1px 1px black;}
#part3Video #btnTap.hide { display: none;}

#subLabelDoor {position: absolute; bottom: -80px; left: calc(50vw - 180px); width: 360px; transition: all 0.3s; color: white; font-size: 16px; text-align: center;}
#btnOpenDoor {position: absolute; bottom: -100px; left: calc(50vw - 120px); width: 240px; transition: all 0.3s;}
#subLabelDoor.active {bottom: 80px;}
#btnOpenDoor.active {bottom: 20px;}

.menu-icon {position: absolute; left: 30px; bottom: 20px; width: 60px; height: 60px; border: 1px solid white; border-radius: 50%; background-color: #1621A2; flex-direction: column; transition: all 0.3s; display: none;}
.menu-icon div {  width: 20px; height: 2px; background-color: #FFF; margin: 3px 0;}
.menu-icon.show {display: flex; z-index: 1;}

#icon360 {position: absolute; bottom: 60px; width: 100px; left: 120px; display: none;}
#icon360.show {display: block; z-index: 1;}

.bottom-trans {position: absolute; bottom: -240px; opacity: 0; transition: all 0.3s; z-index: 1;}
#menuBar {bottom: -240px; left: 20px;}
#btnTVNext {bottom: -70px; right: 20px; padding: 0px 20px; font-family: 'samsungOne700';}
.bottom-trans.open {bottom: 20px !important; opacity: 1;}
#btnTVNext.hide {right: -220px;}
.button-center {position: absolute; top: 20px; right: 20px; padding: 0 25px; display: none; font-family: 'samsungOne700';}
.button-center.show {display: flex;}

#partScan{ position: fixed; width: 100%; height: 100%; z-index: -1; opacity: 0; transition: all 0.3s; background-image: url('../images/scan-back.png'); background-size: contain;}
#partScan.active { opacity: 1; z-index: 1; }
#partScan label {margin-bottom: calc(100vh - 35px);}
#partScan img {width: 100%; height: 10px; position: absolute; left: 0;}
#partScan.scan img { animation: scan 3.5s infinite;}
@keyframes scan { 0% { top: 5vh; } 50% { top: 60vh; } 100% { top: 10vh; } }
@-webkit-keyframes scan { 0% { top: 5vh; } 50% { top: 60vh; } 100% { top: 10vh; } }


.menu-wrapper .close-bar { margin-top: -15px; text-align: right; margin-right: -10px;}
.menu-wrapper .close {position: relative;}
.menu-wrapper {position: relative; padding: 10px 20px; padding-top: 20px; background-color: rgba(0, 0, 0, 0.95);}
.menu-wrapper .menu-item { display: flex; align-items: center; padding: 7px 0px; margin: 10px 0px;}
.menu-wrapper .menu-item img { width: 30px; height: auto; margin-right: 15px; display: initial;}
.menu-wrapper .menu-item img.full { width: 90px;}
.menu-wrapper .menu-item label { color: white; font-size: 14px; font-weight: 700;}

/* .part4-photo .black-wrapper {background: none;} */
.part4-photo .logo-part {width:40%; flex-direction:column;}
.part4-photo .photo-part {width:60%; flex-direction:column;}
.part4-photo .logo-part .logo {width:220px; margin-bottom:20px;}

.part4-photo .photo-part .ready-photo {display: flex;}
.part4-photo .photo-part .unready-photo {display: flex; height: 130px;}

.part4-photo .photo-part .photo-area {width:266px; height: 155px; border:3px solid white; margin-bottom:20px;}
.part4-photo .photo-part .photo-area img {width: 100%; height: 100%;}

.part4-photo .photo-part .button { margin:10px; width: 240px;}
.part4-photo .photo-part .button img {height:30px; margin-right:10px;}

.part4-photo .photo-part .icon-area {margin: 10px 0px; margin-top: 40px;}
.part4-photo .photo-part .icon-area .photo-icon { margin: 0px 10px; display: flex; flex-direction: column; align-items: center;}
.part4-photo .photo-part .icon-area .photo-icon img {width: 35px; height: 35px; border-radius: 50%; border: 1px solid white; margin-bottom: 10px;}
.part4-photo .photo-part .icon-area .photo-icon label {color: white; text-align: center; font-family: samsungBold; font-size: 16px;}

.part5-thanks {flex-direction: column;}
.part5-thanks .logo {width: 240px; margin-bottom: 40px;}
.part5-thanks label {color: white; font-family: samsungBold; font-size: 30px;}


.part-rotate {left:0px; top:-100vh; position:fixed; z-index: 4 !important; background-color: rgba(0, 0, 0, 0.3); width: 100%; height: 100%; opacity: 1;}
.part-rotate .black-wrapper {flex-direction:column; line-height: 150%; background-color:black}
.part-rotate .black-wrapper div {flex-direction: column;}
.part-rotate .black-wrapper label {font-family: samsungBold; font-size: 22px;}
.part-rotate .black-wrapper .button {padding:0px 50px; margin-top:15px;}
.part-rotate .black-wrapper img {height:150px; margin-bottom:15px;}
.part-rotate.noLand .black-wrapper img {transform: rotate(-90deg) scale(1, -1); }
.part-rotate.hide {display: none;}

.pulse {animation: pulse 1.5s infinite; -webkit-animation: pulse 1.5s infinite;}
.pulse:hover {animation: none; -webkit-animation: none; }

/* #btnExitFullScreen {width: 100px; height: 50px; opacity: 1; position: absolute; bottom: 20px; right: 20px; background-color: white; z-index: 1;} */

@keyframes pulse {
	0% {  }
	50% { box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 50%); }
	100% { box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 0%); }
}
@-webkit-keyframes pulse {
	0% {  }
	50% { box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 50%); }
	100% { box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 0%); }
}

.scroll-y { overflow-y: auto; padding-right: 10px;}
.scroll-x { overflow-x: auto; padding-bottom: 10px;}
.scroll::-webkit-scrollbar { width: 5px; height: 5px; }
.scroll::-webkit-scrollbar-track { background: #DCDEDF; }
.scroll::-webkit-scrollbar-thumb { background: #1621A2; }
.scroll::-webkit-scrollbar-thumb:hover { background: #888; }

.part-rotate.active {top:0px;}

@media (orientation: portrait) {
	.part1-select .black-wrapper {flex-direction:column;}
	.black-wrapper .select-part {margin:20px 0px;}
	.part4-photo .black-wrapper {flex-direction:column; width: calc(100vw - 50px); height: calc(100vh - 150px);}
	.part4-photo .black-wrapper .sub-part {width:100%;}
	.part4-photo .black-wrapper .logo-part {margin-bottom:20px;}
	/* .part-rotate.noPort {top:0px;} */
}
@media (orientation: landscape) {
	/* .part-rotate.noLand {top:0px;} */
	.iOS {height: 100%;}
	.iOS a-scene{height: 100%;}
	.part1-select .terms-button {position: absolute; left: 10px; bottom: 65px; width: calc(50vw - 45px);}
	.part1-select .terms-label {position: absolute; left: 10px; bottom: 15px; width: calc(50vw - 45px);}
	.part4-photo .logo-part {width: 50%;}
	.part4-photo .photo-part {width: 50%;}
	.part4-photo .photo-part .button {position: absolute; top: calc(50% + 50px); left: calc(25vw - 100px);}
	.part4-photo .photo-part .unready-photo {display: none;}
	.part4-photo .icon-area {position: absolute; width: calc(50vw - 45px); left: 45px; bottom: 30px;}
}
